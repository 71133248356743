<template>
  <div>
    <h1 class="align-c font42">{{ projectTitle }}</h1>
    <div>
      <el-radio-group v-model="addProjectVal.projectType" @change="changeRadio">
        <!-- <el-radio-button label="0">所有项目</el-radio-button> -->
        <el-radio-button label="1">战略咨询服务</el-radio-button>
        <el-radio-button label="2">品牌全案服务</el-radio-button>
        <el-radio-button label="3">品牌孵化业务</el-radio-button>
        <!-- <el-radio-button label="4">私域增长</el-radio-button> -->
      </el-radio-group>
    </div>
    <div class="flex-c mt20">
      <h5>排序：</h5>
      <el-input v-model="addProjectVal.sort" clearable placeholder="不填默认排序"></el-input>
    </div>
    <div class="flex-c mt20">
      <h5>新增标题：</h5>
      <el-input v-model="addProjectVal.title" clearable></el-input>
    </div>
    <div class="flex-c mt20">
      <h5>新增简介：</h5>
      <el-input v-model="addProjectVal.desc" clearable></el-input>
    </div>
    <div class="new-img flex-c mt20">
      <h5>预览图片：</h5>
      <el-upload
        :action="imgUrl"
        :headers="headersToken"
        :limit="1"
        accept="image/*"
        :before-upload="beforeUpload"
        :on-preview="onRreview"
        list-type="picture-card"
        :on-success="onSuccess"
        :on-remove="onRemove"
        :on-exceed="onExceed"
        name="imgFile"
        :file-list="fileList"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <p class="ml100">上传图片 尺寸：414 * 386</p>
    </div>
    <div class="new-detail flex-c mt20">
      <h5>详情图片：</h5>
      <el-upload
        :action="imgUrl"
        :headers="headersToken"
        :limit="1"
        accept="image/*"
        :before-upload="beforeUploadSon"
        :on-preview="onRreviewSon"
        list-type="picture-card"
        :on-success="onSuccessSon"
        :on-remove="onRemoveSon"
        :on-exceed="onExceedSon"
        name="imgFile"
        :file-list="fileListSon"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisibleSon">
        <img width="100%" :src="dialogImageUrlSon" alt="" />
      </el-dialog>
      <p class="ml100">上传图片 尺寸：1300 * 700</p>
    </div>
    <h5 class="mt20">内容</h5>
    <div>
      <div id="editor"></div>
    </div>

    <div class="mt20">
      <!-- <el-button @click="editBoxShow = false">取消</el-button> -->
      <div v-if="projectTitle == '新增案例'">
        <el-button type="primary" @click="addProjectSubmit">提交</el-button>
      </div>
      <div v-else>
        <el-button @click="goBack">返回</el-button>
        <el-button type="primary" :loading="updateLoading" @click="updateProjectSubmit">修改</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { addProject, getProjectById, updateProject } from "@/apis/projects";
import { apiAll } from "@/utils/utils";
import E from "wangeditor";

export default {
  components: {},
  props: {},
  data() {
    return {
      projectTitle: "新增案例",
      // *上传的内容
      addProjectVal: {
        content: "",
        desc: "",
        title: "",
        image: "",
        projectType: 1,
        contentImage: "",
        sort: "",
      },
      imgUrl: "", // *上传预览图片的地址
      urlApi: "", // *回显的地址
      headersToken: {}, // *上传图片的token
      dialogVisible: false, // *图片预览框是否显示
      dialogVisibleSon: false, // *图片详情是否显示
      dialogImageUrl: "", // *图片预览框的图片地址
      dialogImageUrlSon: "", // *图片详情的图片地址
      editor: "", //* 富文本编辑器
      fileList: [], //* 预览图片回显
      fileListSon: [], //* 详情图片回显
      updateID: "", //* 是否是修改页面
      updateLoading: false, //* 修改按钮是否加载中
    };
  },
  watch: {
    //* 监听路由变化
    $route(nval) {
      if (!nval.query.id) {
        this.projectTitle = "新增案例";
        this.addProjectVal = {
          content: "",
          desc: "",
          title: "",
          image: "",
          contentImage: "",
          projectType: 1,
          sort: "",
        };
        this.editor.txt.html("");
        this.fileList = [];
        this.fileListSon = [];
      }
    },
  },
  computed: {},
  mounted() {
    this.imgUrl = apiAll + "banner/upload "; // *上传图片的地址
    // *添加请求头
    this.headersToken = {
      token: localStorage.getItem("token"),
    };
    this.urlApi = apiAll + "images/"; // *回显的地址
    this.initWangeditor();

    // *获取地址栏传过来的id
    this.updateID = this.$route.query.id;
    if (this.updateID) {
      this.projectTitle = "修改案例";
      this.getProjectById();
    }
  },
  methods: {
    // *富文本
    initWangeditor() {
      // 创建了一个wangEditor对象
      this.editor = new E("#editor");
      // 自定义菜单配置
      this.editor.config.menus = [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "backColor",
        "link",
        "list",
        "todo",
        "justify",
        "quote",
        // "emoticon",// 表情
        "image",
        "video",
        "table",
        "code",
        "splitLine",
        "undo",
        "redo",
      ];
      this.editor.config.uploadImgServer = this.imgUrl; // 图片上传的地址，这个是后端写好的

      // 创建
      this.editor.create();

      // 配置 onchange 回调函数
      this.editor.config.onchange = (newHtml) => {
        this.addProjectVal.content = newHtml;
      };
      // 配置触发 onchange 的时间频率，默认为 200ms
      this.editor.config.onchangeTimeout = 200; // 修改为 500ms

      // 设置请求头
      this.editor.config.uploadImgHeaders = {
        token: localStorage.getItem("token"),
      };
      this.editor.config.uploadFileName = "imgFile"; // 后端接受上传文件的参数名
      this.editor.config.uploadImgHooks = {
        customInsert: (insertImg, result) => {
          // result为上传图片成功的时候返回的数据，这里我们需要后端返回的图片地址，输出一下就能拿到
          //   console.log(insertImg, result.data.file, editor);
          let url = result.data.file;
          insertImg(`${this.urlApi}${url}`); // 图片的函数
        },
      };
    },

    // **********  预览  ***************************************************************************************************************
    // *图片限制长宽
    beforeUpload(file) {
      let _this = this;
      if (file.type.indexOf("image") < 0) {
        _this.$message({
          message: `请上传图片文件`,
          type: "warning",
        });
        return false;
      }
      //   const isLt2M = file.size / 1024 / 1024 < 2;
      const isSize = new Promise(function(resolve, reject) {
        let width = 414;
        let height = 386;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function() {
          let valid = img.width == width && img.height == height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          _this.$message({
            message: `上传图片尺寸只能是414*386px!请重新选择!`,
            type: "warning",
          });
          return Promise.reject();
        }
      );
      return isSize;
    },
    // *图片上传成功
    onSuccess(val) {
      this.addProjectVal.image = `${this.urlApi}${val.data.file}`;
    },
    // *删除图片
    onRemove() {
      if (this.addProjectVal.image) {
        this.addProjectVal.image = "";
      }
    },
    // *图片是否可以预览
    onRreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // *图片上传超出个数
    onExceed() {
      this.$message({
        message: `当前只能上传一个文件`,
        type: "warning",
      });
    },

    // **********  详情  ***************************************************************************************************************
    // *图片限制长宽
    beforeUploadSon(file) {
      let _this = this;
      if (file.type.indexOf("image") < 0) {
        _this.$message({
          message: `请上传图片文件`,
          type: "warning",
        });
        return false;
      }
      //   const isLt2M = file.size / 1024 / 1024 < 2;
      const isSize = new Promise(function(resolve, reject) {
        let width = 1300;
        let height = 700;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function() {
          let valid = img.width == width && img.height == height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          _this.$message({
            message: `上传图片尺寸只能是1300*700px!请重新选择!`,
            type: "warning",
          });
          return Promise.reject();
        }
      );
      return isSize;
    },
    // *图片上传成功
    onSuccessSon(val) {
      this.addProjectVal.contentImage = `${this.urlApi}${val.data.file}`;
    },
    // *删除图片
    onRemoveSon() {
      if (this.addProjectVal.contentImage) {
        this.addProjectVal.contentImage = "";
      }
    },
    // *图片是否可以预览
    onRreviewSon(file) {
      this.dialogImageUrlSon = file.url;
      this.dialogVisibleSon = true;
    },
    // *图片上传超出个数
    onExceedSon() {
      this.$message({
        message: `当前只能上传一个文件`,
        type: "warning",
      });
    },

    // *提交
    addProjectSubmit() {
      //*获取本地时间用 "-"
      //   let createTime = new Date().toLocaleString().replaceAll("/", "-");
      //   this.addProjectVal.createTime = createTime;
      // *提示没有填写标题
      if (!this.addProjectVal.title) {
        this.$message({
          message: `请填写标题`,
          type: "warning",
        });
        return;
      }
      // *提示没有填写简介
      if (!this.addProjectVal.desc) {
        this.$message({
          message: `请填写简介`,
          type: "warning",
        });
        return;
      }
      // *提示没有填写图片
      if (!this.addProjectVal.image) {
        this.$message({
          message: `请上传图片`,
          type: "warning",
        });
        return;
      }
      // *提示没有填写内容
      if (!this.addProjectVal.content) {
        this.$message({
          message: `请填写内容`,
          type: "warning",
        });
        return;
      }

      addProject(this.addProjectVal).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            message: `添加成功`,
            type: "success",
          });
          // *清空 / 重置
          this.addProjectVal = {
            content: "",
            type: 0,
          };
          this.fileList = [];
          this.fileListSon = [];
          this.editor.txt.html("");
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },

    // *编辑项目
    getProjectById() {
      getProjectById({ id: this.updateID }).then((res) => {
        if (res.data.data.image) {
          this.fileList = [
            {
              url: res.data.data.image,
            },
          ];
          this.fileListSon = [
            {
              url: res.data.data.contentImage,
            },
          ];
        }
        this.addProjectVal.image = res.data.data.image;
        this.addProjectVal.contentImage = res.data.data.contentImage;
        this.addProjectVal.title = res.data.data.title;
        this.addProjectVal.desc = res.data.data.desc;
        this.addProjectVal.projectType = res.data.data.projectType;
        this.addProjectVal.sort = res.data.data.sort;
        this.editor.txt.html(res.data.data.content);
      });
    },

    // *修改提交
    updateProjectSubmit() {
      // *提示没有填写标题
      if (!this.addProjectVal.title) {
        this.$message({
          message: `请填写标题`,
          type: "warning",
        });
        return;
      }
      // *提示没有填写简介
      if (!this.addProjectVal.desc) {
        this.$message({
          message: `请填写简介`,
          type: "warning",
        });
        return;
      }
      // *提示没有填写图片
      if (!this.addProjectVal.image) {
        this.$message({
          message: `请上传图片`,
          type: "warning",
        });
        return;
      }
      // *提示没有填写内容
      if (!this.addProjectVal.content) {
        this.$message({
          message: `请填写内容`,
          type: "warning",
        });
        return;
      }

      this.updateLoading = true;

      updateProject({ id: this.updateID, ...this.addProjectVal }).then((res) => {
        if (res.data.code == 0) {
          setTimeout(() => {
            this.updateLoading = false;
          }, 1000);
          this.$message({
            message: `修改成功`,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    // *返回
    goBack() {
      this.$router.go(-1);
    },

    // *项目类型
    changeRadio(val) {
      this.addProjectVal.projectType = val;
    },
  },
  // *销毁富文本编辑器
  destroyed() {
    this.editor = "";
  },
};
</script>
<style lang="less" scoped>
h5 {
  width: 120px;
  line-height: 42px;
  font-size: 18px;
}
/deep/.w-e-menu {
  z-index: 2 !important;
}
/deep/.w-e-text-container {
  z-index: 1 !important;
}
/deep/.w-e-toolbar {
  z-index: 2 !important;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  // e75012
  background-color: #e75012;
  border-color: #e75012;
  box-shadow: -1px 0 0 0 #e75012;
}
/deep/.el-radio-button__inner:hover {
  color: #e75012;
}
/deep/.is-active {
  .el-radio-button__inner:hover {
    color: #fff;
  }
}

.new-img {
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 138px;
    height: 128px;
  }
  /deep/.el-upload--picture-card {
    width: 138px;
    height: 128px;
    line-height: 128px;
  }
}
.new-detail {
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 325px;
    height: 175px;
  }
  /deep/.el-upload--picture-card {
    width: 325px;
    height: 175px;
    line-height: 175px;
  }
}
</style>
